import React, { useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { KYC_FORM_LINK, OFFICIAL_WA_LINK, SUPP_LEVEL_TC, SUPP_RATING_LEVELS } from '../Constants';
import { MainContext } from '../Utility';
import { db } from '../firebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import Rating from "@mui/material/Rating";
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const SuppLevelView = ({
  userRating = {},
  onClose,
  open
}) => {
  const { rating = {}, commissionRate = 0, totalBookings = 0 } = userRating || {};
  const {
    stars: ratingStars = '', label: ratingLabel = ""
  } = rating || {};

  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user") || null) || {};
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(userData?.subscriptionAccepted || false);
  const { isKycVerified = false, subscriptionAccepted = false } = userData;
  console.log("USER_CHECK:", userData, rating);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const phone = userData?.phone;
      if (!phone) {
        console.error("User phone number is missing.");
        return;
      }

      const userDocRef = doc(db, "userDetails", phone);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists() || !userDoc.data()) {
        console.error("User document is missing or has no data.");
        return;
      }

      const existingData = userDoc.data();
      const updatedData = { 
        ...existingData, 
        subscriptionAccepted: true,
        // commission: {
        //   commissionRate: commissionRate,
        //   updatedAt: Date.now()
        // } 
      };
      await setDoc(userDocRef, updatedData);
      const updatedUserData = { 
        ...userData, 
        subscriptionAccepted: true,
        // commission: {
        //   commissionRate: commissionRate,
        //   updatedAt: Date.now()
        // }
      };
      localStorage.setItem("user", JSON.stringify(updatedUserData));

      console.log("Subscription accepted and updated successfully!");
      setLoading(false);
      onClose();
    } catch (error) {
      console.error("Error updating subscription:", error);
    }
  };


  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={isMobile}
      maxWidth="sm"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 1,
          border: '1px solid #ddd',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <DialogContent
        sx={{ 
          overflowY: 'auto',
          // maxHeight: isMobile ? '80vh' : '100%',
          px: { xs: 1, md: 2 },
          pt: 3,
          pb: 10
        }}
      >        
        {!isKycVerified &&
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 2, color: '#333' }}>
              Welcome! Please submit your KYC details (mandatory)
            </Typography>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                fontWeight: 'bold',
                mb: 3,
              }}
              onClick={() =>
                window.open(
                  KYC_FORM_LINK,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              Submit KYC
            </Button>
          </Box>
        }
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ color: '#0081ff', textAlign: "center" }}
        >
          Congratulations! You are a {ratingLabel} member
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: '#666', textAlign: "center" }}>
          You have {totalBookings} Bookings on CabEasy
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between', 
            alignItems: 'stretch',
            mt: 3,
            gap: { xs: 1, sm: 1, md: 1 }, 
          }}
        >
          {SUPP_RATING_LEVELS.map((level, index) => (<Box display={'flex'} flexDirection={'column'} sx={{ width: '30%' }}>
            <Box>
              <Typography variant='subtitle2' sx={index == 0 ? { color: 'white', textAlign: 'center', fontSize: '0.7em' } : {textAlign: 'center', fontSize: '0.7em'}}>{`( ${level?.minPoints}+ Bookings )`}</Typography>
            </Box>
            <Box
              key={index}
              sx={{
                flex: '1 1 calc(33.33% - 8px)',
                textAlign: 'center',
                backgroundColor: 'rgba(17, 17, 17, 0.97)',
                color: 'white',
                border: level.stars === ratingStars ? `3px solid #fcd33a` : `1px solid #fcd33a`,
                borderRadius: 2,
                p: { xs: 1, sm: 2 }, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ textAlign: 'center' }}
              >
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  color="primary"
                  sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}
                >
                  {level.title}
                </Typography>
                {level.stars === ratingStars && (
                  <CheckCircleIcon
                    sx={{
                      color: '#fcd33a',
                      fontSize: { xs: 18, sm: 24 }, 
                      ml: 1,
                    }}
                  />
                )}
              </Box>
              <Box component="ul" sx={{ textAlign: 'left', pl: "1rem" }}>
                {level.perks.map((perk, i) => (
                  <li key={i}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: { xs: '0.65rem', sm: '0.8rem' },
                        lineHeight: { xs: '1rem', sm: '1.2rem' },
                        mb: 0.5,
                        color: i === 0 ? 'white' : '#0caccc',
                      }}
                    >
                      {perk}
                    </Typography>
                  </li>
                ))}
              </Box>
              <Box sx={{ mt: 1 }}>
                <Rating
                  value={level.stars}
                  readOnly
                  precision={0.5}
                  size="small"
                  sx={{
                    '& .MuiRating-iconEmpty': {
                      display: 'none',
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>))}
        </Box>



        <Box component="ul" sx={{ pl: 3, mt: 3, textAlign: 'left' }}>
          {SUPP_LEVEL_TC.map((point, index) => (
            <li key={index}>
              <Typography variant="body2">
                {point.text}{' '}
                {point.link && (
                  <Link href={point.link.href} underline="hover" sx={{ color: '#0081ff' }}>
                    {point.link.text}
                  </Link>
                )}
              </Typography>
            </li>
          ))}
        </Box>

        
        <FormControlLabel
          control={<Checkbox color="primary" />}
          label="I have read and understood the subscription policy. I accept all the responsibilities mentioned as a Supplier on CabEasy"
          checked={isCheckboxChecked}
          onChange={handleCheckboxChange}
          sx={{p: 2, fontSize: '10px'}}
        />
        <Box display="flex" justifyContent={'flex-end'} sx={{ my: 1 }}>
          <Button
            onClick={onClose}
            variant="outlined"
            color="primary"
            sx={{ textTransform: 'none', width: '20%' }}
          >
            {userData?.subscriptionAccepted ? 'Close' : 'Later'}
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            sx={{ textTransform: 'none', width: '20%', marginLeft: 1 }}
            disabled={!isCheckboxChecked || userData?.subscriptionAccepted}
          >
            {userData?.subscriptionAccepted ? 'Accepted' : 'Accept'}
          </Button>
        </Box>
        <Typography variant='body2' sx={{ textAlign: "center", mx: 'auto', marginBottom:"1rem", mb: 10, mt: 2, p: 1 }}>
          For any doubts: &nbsp;
          <Link
            href={OFFICIAL_WA_LINK}
            variant="body2"
            sx={{
              textAlign: "center",
              color: "#000",
              fontWeight: "bold",
            }}
          >
            <b>Connect on WhatsApp</b>
          </Link>
        </Typography>
      </DialogContent>

      {/* <DialogActions sx={{ px: 3, py: 2 }}>
        <Button
          onClick={props.onClose}
          variant="outlined"
          color="primary"
          sx={{ textTransform: 'none', width: '20%' }}
        >
          Later
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{ textTransform: 'none', width: '20%', marginLeft: 1 }}
          disabled={!isCheckboxChecked}
        >
          Accept
        </Button>
      </DialogActions> */}
    </Dialog >
  );
};

export default SuppLevelView;