/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import React, { useEffect, useMemo, useState } from "react";
import { doc, onSnapshot, getDoc, updateDoc } from "firebase/firestore";

import { useNavigate, useLocation } from "react-router-dom";
import FullScreenAppPopup from "../Commons/fullScreenAppPopup";
import MyDailog from "../Commons/myDailog.jsx";
import Navbar from "../Navbar";
import { MainContext, devConsolelog } from "../Utility";
import KycPending from '../SignUp/kycPending';
import { auth, db } from "../firebaseConfig.js";
import SnackbarMsg from "../Commons/snackbarMsg";
// import { AGENT_RATING_LEVELS, SUPP_RATING_LEVELS } from "../Constants.js";
import SuppLevelView from "../SuppLevelView/index.js";

const RedirectComponent = () => {
  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // window.location = "/login";
    navigate("/login", { state: { loginRedirectUrl: location.pathname } });
  }, []);
};

function PrivateRoute({ children, authed = false, props }) {
  if (typeof window !== "undefined") {
    console.log("PrivateRoute - ", props, authed);
    if (authed) return <>{children}</>;
    else return <RedirectComponent />;
  } else {
    return null;
  }
}

// check for the last day when kyc pop up was shown, show only once a day. 
const lastKycShowTime = localStorage.getItem("kycPopUpTime");
const checkShowKycPop = Date.now() - (Number(lastKycShowTime) || Date.now()) > 86400000;
// (new Date(Date.now()).getDate() - new Date(Number(lastKycShowTime || Date.now())).getDate()) >= 1;
// if (lastKycShowTime === null) localStorage.setItem("kycPopUpTime", Date.now());
console.log("checkShowKycPop", checkShowKycPop, new Date(Date.now()).getDate(), new Date(Number(localStorage.getItem("kycPopUpTime") || Date.now())).getDate());

const AppLayout = ({ children, showNavBar = true }) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const isUserAgent = Boolean(userData?.isAgent);
  const isLoggedIn = Boolean(userData); // !isEmptyObject(userData);
  const [showSnackbar, setShowSnackbar] = useState({ open: false });
  const [showAgentKyc, setAgentKyc] = useState(checkShowKycPop);
  const [userRating, setUserRating] = useState(null);
  // let userRating = { rating: 0 };
  let feedBackRatings = userData?.ratings || {}
  devConsolelog("AppLayout render ", showAgentKyc)
  // const IS_KYC_POPUP_SKIPPED =
  //   localStorage.getItem("kyc-popup-skip") === "true" || false;

  useEffect(() => {
    const getUserData = async () => {
      const userDocRef = doc(db, "userDetails", phone);
      const userDataSnap = await getDoc(userDocRef);
      if (!userDataSnap.exists()) return;

      const newUserData = userDataSnap.data();
      delete newUserData["adminRemarks"];
      delete newUserData["kycDocs"];
      console.log("user kyc ", phone, newUserData, userData, newUserData?.subscriptionAccepted, newUserData.isKycVerified, userData?.isKycVerified);
      localStorage.setItem('user', JSON.stringify(newUserData));
      // setUserData(newUserData);
      if (newUserData.isKycVerified != userData?.isKycVerified) {
        if (newUserData.isKycVerified) setShowSnackbar({
          message: "Your Account is now Verified!",
          open: true,
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      }
      if (!userRating) fetchUserStats(userData);
      fetchUserIdToken();
    }

    let { phone = '' } = userData || {};
    if (!phone) {
      console.error("Phone number is not available in userData.");
      return;
    } else getUserData();
  }, [userData]);

  const fetchUserIdToken = async () => {
    try {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          // user.getIdToken().then(function(data) {
          //   console.log(data)
          // });
          let signedInIdToken = await auth.currentUser.getIdToken(
            /* forceRefresh */ true,
          );
          console.log("signedInIdToken ", signedInIdToken, typeof signedInIdToken);
          localStorage.setItem('user', JSON.stringify({ ...userData, firebaseIdToken: signedInIdToken }));
          userData['firebaseIdToken'] = signedInIdToken;
        }
      });
    } catch (e) {
      console.log("signedInIdToken error ", e);
    }
  };

  // useEffect(() => {
  //   if (!userData?.phone) return;
  //   fetchUserStats(userData);
  // }, [userData])

  const contextValue = useMemo(
    () => ({
      userData,
      isUserAgent,
      isLoggedIn,
      showKycPopup: setAgentKyc,
      userRating
    }),
    [userData, isUserAgent, isLoggedIn, userRating]
  );

  const handleClose = () => {
    devConsolelog("close kyc popup", showAgentKyc, checkShowKycPop);
    setAgentKyc(false);
  };

  const fetchUserStats = async (userData = {}) => {
    let userPhone = userData?.phone;
    console.log("supp rating level start ", userPhone, isUserAgent)
    try {
      // const userDetailsRef = doc(db, "userDetails", userPhone);
      // const userDetailsSnap = await getDoc(userDetailsRef);
      // if (userDetailsSnap.exists()) {
      //   setOverallRating(userDetailsSnap.data()?.ratings?.overallRating || null);
      // }

      const userStatisticsRef = doc(db, "userStatistics", userPhone);
      const userStatisticsSnap = await getDoc(userStatisticsRef);
      if (!userStatisticsSnap.exists()) return;
      let statsData = userStatisticsSnap.data();
      let ratingLevel = statsData?.rating || {};
      let explicitCommissionRate = statsData?.commissionRate;
      console.log("explicitCommissionRate ", explicitCommissionRate, ratingLevel?.commissionRate)
      // calcUserRatings({ userData, statsData });
      setUserRating({
        // rating: ratingLevel?.stars || 0,
        // ratingLabel: ratingLevel?.label,
        commissionRate: explicitCommissionRate || ratingLevel?.commissionRate,
        ...statsData
      })
    } catch (error) {
      console.error("Error fetching agent rating:", error);
    }
  };

  useEffect(() => {
    if (!Boolean(userData)) return;
    // if (!isUserAgent && !userData.isKycVerified)
    //   navigate("/kyc-pending");
    // else 
    console.log('show kyc popup 1', showAgentKyc, checkShowKycPop, !userData?.isKycVerified, !userData.isKycVerified && checkShowKycPop);
    if (!userData.isKycVerified && showAgentKyc) {
      console.log('show kyc popup 1 22', showAgentKyc, checkShowKycPop, !userData?.isKycVerified, !userData.isKycVerified && checkShowKycPop);
      setAgentKyc(showAgentKyc);
      // if(checkShowKycPop) {
      try {
        localStorage.setItem("kycPopUpTime", Date.now());
      } catch (e) {
        console.log("localStorage kycPopUpTime error, e")
      }
      // }
    }

    //get Stats


  }, [userData]);

  console.log('layout render ', userData, isLoggedIn, userRating, showAgentKyc, isUserAgent, showAgentKyc && isUserAgent);
  return (<>
    {/** (<FullScreenAppPopup handleClose={handleClose} open={showAgentKyc} />) **/}

    <PrivateRoute authed={isLoggedIn}>
      <MainContext.Provider value={contextValue}>
        <div id="myDiv" className="animate-bottom">
          {showNavBar && <Navbar />}
          <Container maxWidth="md" fixed>
            <Box component="main" sx={{ pt: 9, pb: 5 }}>
              {children}
            </Box>
          </Container>
        </div>
      </MainContext.Provider>
    </PrivateRoute>
    {showAgentKyc && isUserAgent && <MyDailog showDailog={showAgentKyc} body={<KycPending />} handleClose={handleClose} />}
    {showAgentKyc && !isUserAgent && userRating && <SuppLevelView open={showAgentKyc} userRating={userRating || {}} onClose={handleClose} />}    
    {showSnackbar && (
      <SnackbarMsg
        open={showSnackbar.open}
        message={showSnackbar.message}
        anchorOrigin={showSnackbar.anchorOrigin}
        severity={showSnackbar.severity || "success"}
        onClose={() => setShowSnackbar({ open: false })}
      />
    )}
  </>);
};

export default AppLayout;
