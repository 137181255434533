import React, { useState, useEffect, useContext } from "react";
import { doc, getDoc } from "firebase/firestore";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { AGENT_RATING_LEVELS } from "../Constants.js";
import { db } from "../firebaseConfig";
// import { calcUserRatings } from "../Utility.js";

const AgentRatingsView = ({ agentId, trackingId }) => {
  const [userRating, setOverallRating] = useState({});
  let {
    stars: ratingStars = 0,
    label: ratingLabel = ''
  } = userRating?.rating || {};
  
  useEffect(() => {
    const fetchAgentDetails = async () => {
      try {
        // const userDetailsRef = doc(db, "userDetails", agentId);
        // const userDetailsSnap = await getDoc(userDetailsRef);
        // if (userDetailsSnap.exists()) {
        //   setOverallRating(userDetailsSnap.data()?.ratings?.userRating || null);
        // }

        const userStatisticsRef = doc(db, "userStatistics", agentId);
        const userStatisticsSnap = await getDoc(userStatisticsRef);
        if (userStatisticsSnap.exists()) {
          let statsData = userStatisticsSnap.data();
          // const score = Number(statsData?.totalBookings || 0) / Number(statsData?.totalReqsPosted || 0);
          // console.log("agent rating score ", score, statsData, agentId, trackingId);
          // let ratingLevel = AGENT_RATING_LEVELS.find((i) => {
          //   return score > Number(i.minPoints) && score <= Number(i.maxPoints)
          // });
          // ratingLevel = ratingLevel || {};
          // let ratingLevel = statsData?.rating || {};
          // calcUserRatings({"isUserAgent": true /* isUserAgent */, statsData});
          console.log("agent rating level 1 ", statsData, agentId, trackingId);
          setOverallRating({
            // rating: ratingLevel?.stars || 0,
            // ratingLabel: ratingLevel?.label,
            // commissionRate: ratingLevel?.commissionRate,
            ...statsData
          })
          // setTotalBookings(statsData?.totalBookings || 0);
        }
      } catch (error) {
        console.error("Error fetching agent rating:", error);
      }
    };

    fetchAgentDetails();
  }, [agentId]);

  console.log("agent rating render ", userRating, ratingStars)
  if(!userRating?.totalBookings) return null;
  return (
    <>
      {userRating?.totalBookings > 0 && (
        <Box display="flex" alignItems="center" marginTop={1}>
          {ratingStars > 0 && (
            <Rating value={ratingStars} precision={0.5} readOnly size="small" />
          )}
          <Typography variant="body2" color="secondary">
            <small>Agent has {userRating?.totalBookings} Bookings on CabEasy</small>
          </Typography>
        </Box>
      )}
    </>
  );
};

export default AgentRatingsView;
