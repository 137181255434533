import React, { useContext } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import Avatar from '@mui/material/Avatar';
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import CabEasyLogo from "../utills/logoImgBox";
import Chip from "@mui/material/Chip";
import Rating from "@mui/material/Rating";
import { MainContext } from "../Utility";
import SuppLevelView from "../SuppLevelView";
import { AGENT_RATING_LEVELS, SUPP_RATING_LEVELS } from "../Constants";
import AgentLevelView from "../AgentLevelView";
const drawerWidth = 250;

const navItems = [""];

const agentOnlyPages = [
  {
    name: "Post New Requirement",
    link: "/post-req",
  },
  // {
  //   name: "Fixed Departures",
  //   link: "/fds"
  // }
];

const suppOnlyPages = [
  {
    name: "Track Your Bids",
    link: "/my-reqs?tab=trackSupp",
    highlight: true,
  },
  // {
  //   name: "Fixed Departures",
  //   link: "/fds"
  // }
];

const pages = [
  {
    name: "Dashboard",
    link: "/home",
  },
  {
    name: "My Bookings",
    link: "/my-bookings",
  },
  {
    name: "My Requirements",
    link: "/my-reqs",
  },
  {
    name: "My Profile",
    link: "/profile",
  },
  {
    name: "Accounts",
    link: "/accounts",
  },
  // {
  //   name: "Referral",
  //   link: "/referral",
  // },
  // {
  //   name: "Deals & Offers",
  //   link: "/deals",
  //   highlight: true,
  // },
  {
    name: "FAQs",
    link: "/faq",
  },
  {
    name: "Raise Complaint",
    link: "/raise-complaint",
  },
  {
    name: "Logout",
    link: "/logout",
  },
];

function NavBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const isLoggedIn = Boolean(userData);
  const navigate = useNavigate();
  const {
    userRating = {}
  } = useContext(MainContext);
  console.log("CHECK_SUPP_NAV:", userRating);
  const {
    rating: { stars = 3, label = "Bronze" } = {}
  } = userRating || {};
  console.log("userRatinc_CHeck:", userRating)
  let finalRoutes = pages;
  if (isLoggedIn && userData.isAgent)
    finalRoutes = [...agentOnlyPages, ...pages];
  else if (isLoggedIn && userData.isSupplier)
    finalRoutes = [...suppOnlyPages, ...pages];

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleOpenPopup = () => setIsPopupOpen(true);
  const handleClosePopup = () => setIsPopupOpen(false);
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      {isLoggedIn && (
        <>
          <Box display="flex" flexDirection="row" sx={{ backgroundColor: "primary.main" }}>
            {<Avatar alt="logo" src={userData.logo} sx={{ margin: "auto 8px" }} />}

            <Box sx={{ py: 2, px: 1, flexGrow: "1", textAlign: "left" }}>
              {`Hi, ${userData ? `${userData.name || ""}` : ""}`}
              <br />
              <small>{userData.phone}</small>
            </Box>
          </Box>
          <Divider />
        </>
      )}
      <List>
        {finalRoutes.map((page, index) => (
          <ListItem
            className="fw-600"
            key={page.name}
            disablePadding
            onClick={() => navigate(page.link)}
          >
            <ListItemButton>
              <ListItemText className="fw-600" primary={page.name} />
              {page?.highlight && <ListItemIcon>
                <Chip label="New" color="primary" />
              </ListItemIcon>}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  console.log("navbar render ", userRating)
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ background: "#000" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "block" } }}
          >
            <MenuIcon sx={{ color: "#fff" }} />
          </IconButton>
          {/* <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
          >
          </Typography> */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CabEasyLogo navbar={true} />
          </div>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: "#000" }}>
                {item}
              </Button>
            ))}
          </Box>
          { !userData.isAgent && (<Box sx={{ marginLeft: 'auto' }}>
            <Button
              variant="contained"
              sx={{
                color: 'black',
                width: '100%',
                padding: 1,
                fontSize: '12px',
                background: `linear-gradient(145deg, ${userData.isAgent
                    ? AGENT_RATING_LEVELS[stars - 3]?.color
                    : SUPP_RATING_LEVELS[stars - 3]?.color
                  }, #fff)`,
                position: 'relative',
                overflow: 'hidden',
                borderRadius: 10,
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: '-150%',
                  width: '200%',
                  height: '100%',
                  background:
                    'linear-gradient(75deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.4) 100%)',
                  transform: 'skewX(-20deg)',
                  transition: 'all 0.3s ease',
                },
                '&:hover:before': {
                  left: '150%',
                  transition: 'all 0.5s ease-in-out',
                },
              }}
              onClick={handleOpenPopup}
            >
              {label}
              <Rating
                value={stars}
                readOnly
                precision={0.5}
                size="small"
                sx={{
                  marginLeft: 1,
                  '& .MuiRating-iconEmpty': {
                    display: 'none',
                  },
                }}
              />
            </Button>
          </Box>)}
          {userData.isSupplier && <SuppLevelView open={isPopupOpen} userRating={userRating} onClose={handleClosePopup} />}
          {/* {userData.isAgent && <AgentLevelView open={isPopupOpen} userRating={userRating} onClose={handleClosePopup} />} */}
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              // background: "#fefcec",
              fontWeight: 600,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

NavBar.propTypes = {
  window: PropTypes.func,
};

export default NavBar;
